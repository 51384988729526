<template>
  <v-dialog
    scrollable
    @click:outside="handleClose"
    transition="dialog-bottom-transition"
    v-model="value"
  >
    <v-card height="100%">
      <div class="mx-3 mb-3">
        <v-card-actions>
          <v-card-title>Nova Action</v-card-title>
          <v-spacer></v-spacer>
          <v-btn text fab color="red" @click="handleClose()">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="postAtualiza(), getActions">
            Aplicar
          </v-btn>
          <v-btn
            @click="expansionModal = true"
            large
            icon
            color="primary">
            <v-icon size="50">mdi-fullscreen</v-icon>
          </v-btn>
        </v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="name"
              outlined
              v-model="actionNew.name"
            ></v-text-field>
            <v-text-field
              label="icon"
              placeholder="mdi-icon"
              outlined
              v-model="actionNew.icon"
            ></v-text-field>
            <v-text-field
              label="module"
              outlined
              v-model="actionNew.module"
            ></v-text-field>
            <v-text-field
              label="description"
              outlined
              v-model="actionNew.description"
            ></v-text-field>
            <v-combobox
              label="callType"
              outlined
              v-model="actionNew.callType"
              :items="typeActions"
            ></v-combobox>
            <v-combobox
              :items="schemas"
              label="schemaName"
              outlined
              v-model="schemaName"
              item-text="name"
            ></v-combobox>
            <v-combobox
              label="triggerProperty"
              outlined
              v-model="triggerProperty"
              :items="triggerProperties"
              chips
              :multiple="true"
            ></v-combobox>
            <v-text-field
              label="timeout"
              outlined
              v-model="actionNew.timeout"
            ></v-text-field>
            <v-text-field
              label="resultType"
              outlined
              v-model="actionNew.resultType"
            ></v-text-field>
            <div>
              <span class="ml-1 font-weight-bold">Parameters</span>
              <span>
                <v-btn
                  class="ma-2"
                  @click="addParams('actionNew')"
                  text
                  icon
                  color="green lighten-2"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </span>
            </div>
            <div
              class="form-group"
              v-for="(params, k) in actionNew.parameters"
              :key="k"
            >
              <v-row class="mx-1">
                <v-text-field
                  label="name"
                  class="form-control mr-2"
                  outlined
                  v-model="params.name"
                ></v-text-field>
                <v-text-field
                  label="dataType"
                  class="form-control"
                  outlined
                  v-model="params.dataType"
                ></v-text-field>
                <span>
                  <v-btn
                    class="ma-2"
                    @click="removeParams('actionNew', k)"
                    text
                    icon
                    color="red lighten-2"
                  >
                    <v-icon>mdi-minus-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-row>
            </div>
          </v-col>
          <v-col cols="6">
            <prism-editor
              class="my-editor"
              v-model="actionNew.javascriptCode"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog
      scrollable
      transition="dialog-bottom-transition"
      v-model="expansionModal"
    >
      <v-card height="100%">
        <prism-editor
          class="my-editor"
          v-model="actionNew.javascriptCode"
          :highlight="highlighter"
          line-numbers
        ></prism-editor>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import actionsMainMixin from '../../../../mixins/tables/Actions/actionsMainMixin';
export default {
  mixins: [actionsMainMixin],
  props: ['value'],
  data: function () {
    return {
      schemaName: '',
      expansionModal: false
    };
  },
  methods: {},
  mounted() {},
  watch: {
    schemaName(value){
      if(value){
        this.triggerProperties = []
        this.triggerProperty = []
        this.triggerProperties = Object.keys(JSON.parse(value.jsonValue).properties)
        this.actionNew.callConfig.schemaName = value.name
      }
    }
  }
};
</script>

<style>
</style>