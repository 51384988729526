import http from '../../../http/axiosConfig';
import mailTableBtnMixin from './mailActionBtnMixin';
export default {
  mixins: [mailTableBtnMixin],
  data() {
    return {
      search: '',
      tableActions: [],
      schemas: []
    };
  },
  methods: {
    async getActions() {
      let page = this.pagination.page;
      let url = `Action?page=${page}&limit=${this.pagination.rowsPerPage}&sortField=createAt&sortDesc=true`;
      let result = await http.get(url);
      let res = result.data.data ? result.data.data : result.data;
      let tableBody = [];
      if (res.length > 0) {
        res.forEach((inAction) => {
          let createAt = this.convertDate(inAction.createAt);
          let createBy =
            inAction.createBy && inAction.createBy.split('@').shift();

          let Created = `${createAt} (${createBy})`;

          let updateAt = inAction.updateAt ? this.convertDate(inAction.updateAt) : '';
          let updateBy =
            inAction.updateBy ? inAction.updateBy.split('@').shift() : '';

          let LastUpdated = updateAt && updateBy ? `${updateAt} (${updateBy})` : '';

          tableBody.push({
            ...inAction,
            Created: Created,
            LastUpdated: LastUpdated,
          });
        });
      }
      this.tableActions = tableBody;
    },
    async getSchemas(){
      let page = this.pagination.page;
      let url = `Schema?page=${page}&limit=${this.pagination.rowsPerPage}&sortField=createAt&sortDesc=true`;
      let result = await http.get(url)
      this.schemas = result.data.data ? result.data.data : result.data;
    },
    async postActionJson() {
      let actionNew = this.actionNew;
      try {
        let res = await http.post(`Action`, actionNew);
        (res.success || res.data.success) ? this.notifySuccess('Action Cadastrado!') : this.notifyError(res);
        return this.handleClose();
      } catch (error) {
        this.notifyError(error);
      }
    },
    async putActionJson(obj) {
      let stuffEdit = obj;
      let itemId = stuffEdit.id;
      try {
        let res = await http.put(`Action/${itemId}`, stuffEdit);
        console.log(res);
        (res.success || res.data.success) ? this.notifySuccess('Action Alterado!') : this.notifyError(res);
        return this.handleClose();
      } catch (error) {
        this.notifyError(error);
      }

    },
    async deleteAction(item) {
      let itemId = item.id;
      return await http.delete(`Action/${itemId}`);
    },
  },
};