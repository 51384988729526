<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <action-table />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import actionTable from '../components/tables/Actions/actionTable.vue';
export default {
  components: { actionTable },
  name: 'Actions',
};
</script>
