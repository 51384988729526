import getActionsMixin from './getActionMixin';

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
  data() {
    return {
      triggerProperties: [],
      triggerProperty: [],
    }
  },
  mixins: [getActionsMixin],
  components: {
    PrismEditor,
  },
  methods: {
    async putAtualiza(obj) {

      if(this.stuff?.callConfig?.triggerProperty?.length > 0){
        this.stuff.callConfig.triggerProperty = this.stuff?.callConfig?.triggerProperty?.map((item) => { 
          return item 
        }).join(',')
      }
      
      await this.putActionJson(obj);
      return this.getActions();
    },
    async postAtualiza(item) {

      this.actionNew.callConfig.triggerProperty = this.triggerProperty?.map((item) => { 
        return item 
      }).join(',')

      await this.postActionJson(item);
      return this.getActions();
    },
    async deleteAtualiza(item) {
      if(await this.$isConfirmeDelete(`Tem certeza que deseja excluir esse item. <strong>${item.description}</strong>`)){
        await this.deleteAction(item);
        return this.getActions();
      }
    },
    async paginationChangeHandler(value) {
      this.pagination.page = await value;
      this.getActions();
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == 'Created') {
          let created = 'createAt';
          if (!isDesc[0]) {
            return new Date(b[created]) - new Date(a[created]);
          } else {
            return new Date(a[created]) - new Date(b[created]);
          }
        } else if (index[0] == 'LastUpdated') {
          let updated = 'updateAt';
          if (!isDesc[0]) {
            return new Date(b[updated]) - new Date(a[updated]);
          } else {
            return new Date(a[updated]) - new Date(b[updated]);
          }
        }
        else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
            }
            else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    highlighter(code) {
      return highlight(code, languages.js); // languages.<insert language> to return html with markup
    },
  },

  mounted() {
    this.$nextTick(this.getSchemas);
  },

};