<template>
  <div>
    <v-text-field
      outlined
      v-model="search"
      class="mb-5"
      append-icon="mdi-find-replace"
      label="Pesquisar"
      single-line
      hide-details
    ></v-text-field>
    <v-col class="text-right">
      <v-btn color="secondary" @click="showNew = true"> Nova Action </v-btn>
    </v-col>
    <v-data-table
      :headers="headers"
      :items="tableActions"
      :options.sync="pagination"
      :items-per-page="pagination.rowsPerPage"
      hide-default-footer
      class="elevation-1"
      :search="search"
      :custom-sort="customSort"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="viewerDialog(item)">
              <v-list-item-icon>
                <v-icon color="secondary" v-text="'mdi-open-in-new'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Visualizar'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="editDialog(item)">
              <v-list-item-icon>
                <v-icon color="secondary" v-text="'mdi-code-json'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Editar'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="deleteAtualiza(item)">
              <v-list-item-icon>
                <v-icon
                  color="secondary"
                  v-text="'mdi-trash-can-outline'"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Delete Action'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template slot="no-data">
        <v-alert color="orange" class="mt-4" type="error"
          >Desculpe, você não possui Actions! :(
        </v-alert>
      </template>
    </v-data-table>
    <show-new v-model="showNew"></show-new>
    <show-viewer v-model="showViewer" :stuff="stuffViewer"></show-viewer>
    <show-edit v-model="showEdit" :stuff="stuffEdit"></show-edit>
    <v-pagination
      @input="paginationChangeHandler"
      v-model="pagination.page"
      :length="pagination.pages"
      circle
    ></v-pagination>
  </div>
</template>
 
<script>
import actionsMainMixin from '../../../mixins/tables/Actions/actionsMainMixin';

import { HEAD_TABLE } from './headTableConfig';

import showNew from '../../../components/tables/Actions/dialogs/showNew.vue';
import showViewer from '../../../components/tables/Actions/dialogs/showViewer.vue';
import showEdit from '../../../components/tables/Actions/dialogs/showEdit.vue';

export default {
  name: 'ActionsTable',
  mixins: [actionsMainMixin],
  components: {
    showNew,
    showViewer,
    showEdit,
  },
  data() {
    return {
      headers: HEAD_TABLE,
    };
  },
  mounted() {
    this.$nextTick(this.getActions);
  },
};
</script>
