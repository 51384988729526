<template>
  <v-dialog
    v-if="stuff"
    scrollable
    @click:outside="handleClose"
    transition="dialog-bottom-transition"
    v-model="value"
  >
    <v-card height="100%">
      <div class="mx-3 mb-3">
        <v-card-actions>
          <v-card-title
            >Visualizar Action<span class="font-italic">
              (Somente Leitura)
            </span></v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn text fab color="red" @click="handleClose()">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="name"
              outlined
              v-model="stuff.name"
              readonly
            ></v-text-field>
            <v-text-field
              label="icon"
              placeholder="mdi-icon"
              outlined
              v-model="stuff.icon"
              readonly
            ></v-text-field>
            <v-text-field
              label="module"
              outlined
              v-model="stuff.module"
              readonly
            ></v-text-field>
            <v-text-field
              label="description"
              outlined
              v-model="stuff.description"
              readonly
            ></v-text-field>
            <v-text-field
              label="callType"
              outlined
              v-model="stuff.callType"
              readonly
            ></v-text-field>
            <v-text-field
              label="schemaName"
              outlined
              v-model="stuff.callConfig.schemaName"
              readonly
            ></v-text-field>
            <v-text-field
              label="triggerProperty"
              outlined
              v-model="stuff.callConfig.triggerProperty"
              readonly
            ></v-text-field>
            <v-text-field
              label="timeout"
              outlined
              v-model="stuff.timeout"
              readonly
            ></v-text-field>
            <v-text-field
              label="resultType"
              outlined
              v-model="stuff.resultType"
              readonly
            ></v-text-field>
            <div>
              <p class="ml-1 font-weight-bold">Parameters</p>
            </div>
            <div
              class="form-group"
              v-for="(params, k) in stuff.parameters"
              :key="k"
            >
              <v-row class="mx-1">
                <v-text-field
                  label="name"
                  class="form-control mr-2"
                  outlined
                  readonly
                  v-model="params.name"
                ></v-text-field>
                <v-text-field
                  label="dataType"
                  class="form-control"
                  outlined
                  readonly
                  v-model="params.dataType"
                ></v-text-field>
              </v-row>
            </div>
          </v-col>
          <v-col cols="6">
            <prism-editor
              class="my-editor"
              readonly
              v-model="stuff.javascriptCode"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import actionsMainMixin from '../../../../mixins/tables/Actions/actionsMainMixin';
export default {
  mixins: [actionsMainMixin],
  props: ['value', 'stuff'],
  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style>
</style>