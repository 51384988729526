import notifyConfigMixin from '../../notifyConfigMixin';

export default {
  mixins: [notifyConfigMixin],
  data() {
    return {
      showViewer: false,
      showEdit: false,
      showNew: false,
      count: 0,

      stuffViewer: null,
      stuffEdit: null,

      actionNew: {
        name: null,
        icon: null,
        module: null,
        description: null,
        callType: null,
        callConfig: {
          schemaName: null,
          triggerProperty: null
        },
        javascriptCode: null,
        timeout: null,
        resultType: null,
        parameters: [],

      },

      typeActions: ['Schema', 'Separate', 'EventSchemaProperty', 'TabForm'],
      pagination: { pages: 0, rowsPerPage: 1000000000, page: 1 },
    };
  },
  methods: {
    addParams(name) {
      if (name == 'actionNew') {
        this.actionNew.parameters.push({
          name: "", dataType: ""
        });
      } else if (name == 'stuffEdit') {
        this.stuffEdit?.parameters?.push({
          name: "", dataType: ""
        });
      }
    },

    removeParams(name, index) {
      if (name == 'actionNew') {
        this.actionNew.parameters.splice(index, 1);
        if (this.actionNew.parameters.length == 0) {
          this.actionNew.parameters = [];
        }
      } else if (name == 'stuffEdit') {
        this.stuffEdit.parameters.splice(index, 1);
        if (this.stuffEdit.parameters.length == 0) {
          this.stuffEdit.parameters = [];
        }
      }

    },
    convertDate(date) {
      return new Date(date)
        .toLocaleString('pt-BR')
        .slice(0, 16);
    },
    viewerDialog(item) {
      this.stuffViewer = item;
      this.showViewer = true;
    },
    editDialog(item) {
      console.log('stuffEdit ', item)
      this.stuffEdit = item;
      this.showEdit = true;
    },
    handleClose() {
      this.$emit('input', false);
    },
  },
};
